export const Icons = {
  FONT_UP: "FONT_UP",
  FONT_DOWN: "FONT_DOWN",
  BACK: "FONT_BACK",
  SHARE: "SHARE",
  READ_SETTINGS: "READ_SETTINGS",
  FAVOURITE: "FAVOURITE",
  SEARCH: "SEARCH",
  MENU: "MENU",
  NIGHT_TIME: "NIGHT_TIME",
  DAY_TIME: "DAY_TIME",
  TEXT_DIVIDER: "TEXT_DIVIDER",
  CONTENT_LIST: "CONTENT_LIST",
  DELETE: "DELETE",
  IOS_FAVS: "IOS_FAVS",
  CANCEL: "CANCEL",
	HIDDEN_WORDS: "HIDDEN_WORDS"
}

export const FontSize = {
  FONT_SIZE: 16
}

export const Type = {
  FAVORITE: "FAVORITE",
  COLOR_THEME: "COLOR_THEME"
}

export const Global = {
  URL: "https://backend.molitve.mobi/wp-json/wp/v2/"
}