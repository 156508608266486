import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: ${({theme}) => theme && theme.colorBody}; 
`