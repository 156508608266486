import styled from 'styled-components'

export const Page = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .favorite-label {
		position: relative;
    &:before {
			content:'';
      position: absolute;
      top: 0.5rem;
      bottom: 4.5rem;
      left: -1.5rem;
			width: 3px;
			border-top-right-radius: 1.5px;
      border-bottom-right-radius: 1.5px;
			background-color: #f14a3de6;
		}
  }
`;