import React, {Component} from 'react'
import styled from 'styled-components'
import {Icons} from '../Constants'
import {SvgIcons} from '../assets/Svg'

const InstallBannerIos = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(241,74,61,.9), rgba(254, 22, 81, .90));
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
  > div {
    width: 320px;
    height: 350px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    
    > button.close {
      position: absolute;
      z-index: 2;
      top: -30px;
      right: 10px;
      width: 20px;
      height: 20px;
      color: #fff;
    }
    
    >.svg-placeholder {
      margin: 40px 0;
    }
    
    > h2 {
      all: unset;
      font-size: 21px;
      line-height: 26px;
      margin-bottom: 40px;
      text-transform: initial;
      font-weight: bold;
    }
    
    > p {
      font-size: 16px;
      line-height: 21px;
    }
    
    strong {
      font-weight: bold;
    }
    
    > footer {
      background-color: #F4F4F4;
      margin-top: auto;
      font-size: 14px;
      padding: 15px;
      width: 100%;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      
      > svg {
        color: #007AFF;
        transform: translateY(3px);
        width: 20px;
        height: 20px;
      }
    }
  }
`

export default class IosInstall extends Component {
  constructor(){
    super()
    this.state = {
      cancelBanner: false
    }
  }
  
  cancel = () => {
    this.setState({
      cancelBanner: true
    })
  }
  
  render() {
    if (this.state.cancelBanner) {
      return null
    }
    
    return (
      <InstallBannerIos>
        <div>
          <button className="close" onClick={ this.cancel }>{SvgIcons(Icons.CANCEL)}</button>
          <div className="svg-placeholder"><svg width="321" height="59">
            <defs>
              <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0%" stopColor="#F25044"/>
                <stop offset="100%" stopColor="#FF1E57"/>
              </linearGradient>
              <linearGradient id="d" x1="49.988%" x2="50.012%" y1="0%" y2="100%">
                <stop offset="0%" stopColor="#F63745"/>
                <stop offset="100%" stopColor="#812B3E"/>
              </linearGradient>
              <filter id="b" width="145.7%" height="213.2%" x="-22.8%" y="-28.3%" filterUnits="objectBoundingBox">
                <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1"/>
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
                <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0964816434 0"/>
              </filter>
              <path id="c" d="M143.744 33.902l.003 3.334a4 4 0 003.597 3.976c5.341.542 8.258.825 8.75.85.887.044 1.926.326 2.45.876.35.367.65.733.899 1.096h4.411a6.62 6.62 0 01.899-1.096c.472-.456 1.144-.804 2.288-.877.622-.04 3.337-.316 8.145-.83a4 4 0 003.575-3.981l-.003-3.348a4.011 4.011 0 00-4.004-4H147.74a3.994 3.994 0 00-4 3.997c0 .001 0 .002.004.003z"/>
            </defs>
            <g fill="none" fillRule="evenodd" opacity=".951" transform="translate(-.989 .467)">
              <rect width="57" height="57" x="132.654" y=".5" fill="url(#a)" rx="10"/>
              <path fill="#FFF" fillOpacity=".466" d="M139 13.412v31.271a1 1 0 001.003 1c9.745-.03 14.93-.029 15.553.003.967.05 2.099.37 2.67.996.381.417.708.832.98 1.245h4.806c.31-.485.636-.9.98-1.245.514-.518 1.245-.913 2.491-.996.804-.053 6.047-.055 15.73-.005a1 1 0 001.004-1v-31.27a1 1 0 00-1-1H166.27c-1.742 0-3.295.775-4.66 2.324-1.602-1.549-3.074-2.323-4.415-2.323H140a1 1 0 00-1 1z"/>
              <use fill="#000" filter="url(#b)"/>
              <path fill="#FFEEF0" d="M141.7 11v30.516a1 1 0 001.003 1c8.504-.026 13.029-.025 13.576.003.852.044 1.848.326 2.351.877.336.367.623.733.862 1.096h4.233c.273-.426.56-.792.862-1.096.453-.456 1.098-.804 2.195-.877.704-.047 5.281-.048 13.73-.005a1 1 0 001.006-1V11a1 1 0 00-1-1h-14.805c-1.534 0-2.902.682-4.104 2.046-1.411-1.364-2.707-2.046-3.888-2.046H142.7a1 1 0 00-1 1z"/>
              <path fill="url(#d)" opacity=".34" transform="matrix(-1 0 0 1 323.217 0)" d="M161.367 11.68l.232.414.264-.498-.009 32.896h-.5z"/>
              <path fill="#F4F4F4" d="M107.994 1a8 8 0 018 8v41a8 8 0 01-8 8h-41a8 8 0 01-8-8V9a8 8 0 018-8h41zm147.012 0a8 8 0 018 8v41a8 8 0 01-8 8h-41a8 8 0 01-8-8V9a8 8 0 018-8h41zm66.005 0v57h-34a8 8 0 01-8-8V9a8 8 0 018-8h34zM34.99 1a8 8 0 018 8v41a8 8 0 01-8 8h-34V1h34z"/>
            </g>
          </svg></div>
          <h2>Namesti Molitve Baháʼí</h2>
          <p>Namesti aplikacijo na svoj<br/>iPhone
            za hiter in  enostaven<br/>dostop, kadarkoli in kjerkoli.</p>
          <footer>Dotakni se {SvgIcons(Icons.IOS_FAVS)} in nato Add to home screen</footer>
        </div>
      </InstallBannerIos>
    )
  }
}