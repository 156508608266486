export const themes = {
  dark: {
    colorBody: '#101110',
    colorFont: '#999899',
    colorSecondary: '#4A2C07',
    colorBorder: '#222322',
    colorSettings: '#050505',
    fontFamily: 'Georgia'
  },
  
  light: {
    colorBody: '#ffffff',
    colorFont: '#232423',
    colorSecondary: '#b13200',
    colorBorder: '#F2F2F2',
    colorSettings: '#ffffff',
    fontFamily: 'Georgia'
  }
};