import React, { Fragment } from 'react'
import { CSSTransition } from 'react-transition-group'
import AppSettings from './AppSettings'
import { Icons } from '../Constants'
import { SvgIcons } from '../assets/Svg'
import * as SC from './Footer.styles'
import {connect} from "react-redux";

class Footer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showSettings: false,
    }
  }
  
  toggleSettings() {
    this.setState(prevState => ({
      showSettings: !prevState.showSettings
    }))
  }
  
  renderFooter() {
    return (
      <SC.Footer hideFooter={this.props.hide}>
        <button className="back" onClick={this.props.toggleHandler}>
          { SvgIcons(Icons.BACK) }
        </button>
        <button onClick={ () => this.toggleSettings() }>
          { SvgIcons(Icons.READ_SETTINGS) }
        </button>
      </SC.Footer>
    )
  }
  
  render() {
    return (
      <Fragment>
        {this.renderFooter()}
        <CSSTransition
          in={this.state.showSettings}
          timeout={300}
          classNames="settings"
          unmountOnExit
        >
          <AppSettings clickHandler={this.toggleSettings.bind(this)} />
          
        </CSSTransition>
      </Fragment>

    )
  }
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps)(Footer)
