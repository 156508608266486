import styled from 'styled-components'

export const PageList = styled.dl`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  min-height: 0;
  margin-left: 2.5rem;
  dt {
  font-size: 1.2rem;
  padding: 4.25rem 0;
  font-weight: var(--f-bold);
  }
  dd {
    border-bottom: solid 1px ${({ theme }) =>theme && theme.colorBorder};
		&.sectionDescription {
			border-bottom: 0;
			font-style: italic;
			padding: 2rem 1rem 2rem 0;
			line-height: 1.35;
		}
		
		
    > button {
      text-align: left;
      width: 100%;
      padding: 1.25rem 1.25rem 1.25rem 0;
      background: none;
      border: none;
      font-size: inherit;
      font-family: inherit;
      color: ${({theme}) => theme && theme.colorFont};
      > svg {
        width: 12px;
        height: 12px;
        margin-right: .5rem;
        color: ${({theme}) => theme && theme.colorSecondary}
      }
    }
    
    .excerpt {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .author {
      font-size: .85rem;
      opacity: .4;
      font-style: italic;
    }
  
  }
`