import React, { Fragment } from 'react'
import { CSSTransition } from 'react-transition-group'
import PageNavigation from '../PageNavigation.component'
import PrayersContainer from './Prayers.container'
import {Global} from "../../Constants";

export default class Prayers extends React.PureComponent {
  constructor(props) {
    super(props)
    
    this.state = {
      categories: [],
      categoryId: null,
      toggleHeader: true,
      categoryName: '',
    }
  }

  componentDidMount() {
    let categoriesUrl = `${Global.URL}categories?per_page=100`
    fetch(categoriesUrl)
      .then(response => response.json())
      .then(response => {
        this.setState({
          categories: response
        })
      })
  }

  goToCategoryPage(id, categoryName) {
    this.setState(prevState => ({
      toggleHeader: !prevState.toggleHeader,
      categoryId: id,
      categoryName: categoryName
    }))
  }

  togglePage() {
    this.setState(prevState => ({
      toggleHeader: !prevState.toggleHeader
    }))
  }

  renderNavigation() {
    const categories = this.state.categories.map((category, index) => {
    	if (category.id === 35 || category.id === 36 || category.id === 37) {
    		return
			} else {
				return (
					<dd key={index}>
						<button onClick={() => this.goToCategoryPage(category.id, category.name)} data-url={category.id}>
							{category.name}
						</button>
					</dd>
				)
			}
    })
    
    return categories
  }

  render() {
    return(
      <Fragment>
        
        <CSSTransition in={this.state.toggleHeader} classNames="navigation" timeout={300} unmountOnExit>
          <PageNavigation navList={this.renderNavigation()} title="Splošne molitve" />
        </CSSTransition>
        
        <CSSTransition in={!this.state.toggleHeader} classNames="entries" timeout={300} unmountOnExit>
          <PrayersContainer categoryName={ this.state.categoryName } id={ this.state.categoryId } toggleHandler={ this.togglePage.bind(this) }/>
        </CSSTransition>
        
      </Fragment>
    )
  }
}