import styled from 'styled-components'

export const Entry = styled.article`
  padding-bottom: 3rem;
  
  div {
    p:first-of-type::first-line {
      text-transform: uppercase;
    }
  }
  
  p {
    font-size: 1rem;
    text-indent: 1rem;
    line-height: 1.7;

    em {
      padding-right: 2rem;
      margin-bottom: 1.25rem;
    }
    
    &.author {
    text-align: right;
    font-style: italic;
    text-indent: 0;
      &::before {
        content: '— ';
      }
    }
  }
  
  .divider {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    text-align: center;
  }
  
  svg {
    color: ${({theme}) => theme && theme.colorBorder};
    text-align: center;
  }
  `


