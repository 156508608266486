import React, { Fragment, Component } from 'react'
import IosInstall from './components/IosInstallBanner'
import { GlobalReset } from './styles/ResetStyles.styles'
import { GlobalStyles } from './styles/GlobalStyles.styles'
import { AppProvider } from './context/AppContext'
import { AppContext } from './context/AppContext'
import { ThemeProvider } from 'styled-components'
import Page from './components/Page.container'

export default class App extends Component {
  constructor(){
    super()
    this.state = {
      showInstallMessage: false
    }
  }
  
  componentDidMount() {
    this.isIos()
  }
  
  isIos() {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test( userAgent )
    }

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone)
  

    if (isIos() && !isInStandaloneMode()) {
      this.setState({ showInstallMessage: true })
    }
  }
  
  render() {

    return (
      <Fragment>
        <GlobalReset/>
        <AppProvider>
          <AppContext.Consumer>
            {({ theme, fontSize }) => (
              <ThemeProvider theme={theme}>
                <GlobalStyles fontSize={fontSize}/>
                <Page/>
                { this.state.showInstallMessage &&
                <IosInstall/>
                }
              </ThemeProvider>
              )}
          </AppContext.Consumer>
        </AppProvider>
      </Fragment>
    )
  }
}
