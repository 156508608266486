const doubleTapMaxDelay = 300
let latestTouchTap = {
  time: 0,
  target: null
}

export function isDoubleTap(event) {
  const touchTap = {
    time: new Date().getTime(),
    target: event.currentTarget
  }
  const isDoubleTap = (
    touchTap.target === latestTouchTap.target &&
    touchTap.time - latestTouchTap.time < doubleTapMaxDelay
  )
  latestTouchTap = touchTap
  return isDoubleTap
}