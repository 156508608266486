import { createGlobalStyle } from 'styled-components'
export const GlobalStyles = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,564;1,400;1,564&display=swap');

  * {
    box-sizing: border-box;
  }
  
  :root {
    --footer-height: 50px;
    --font: 'Lora', Georgia, serif;
    --f-bold: 564;
    --f-regular: 400;
  }

  
  html {
    font-family: var(--font);
    font-size: ${({fontSize}) => fontSize && fontSize}px;
  }
  
  body {
    background-color: ${({ theme }) =>theme && theme.colorBody};
    color: ${({ theme }) =>theme && theme.colorFont};
  }
  html, body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
  }
  
  aside {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) =>theme && theme.colorBody};
  }
  
  button {
    color: ${({ theme }) =>theme && theme.colorFont};
    border: none;
    background-color: transparent;
    font-size: inherit;
    
    &:focus {
      outline: 0;
    }
  }
  
  h2 {
    text-transform: uppercase;
      text-align: center;
    padding: 3rem 0 4.25rem 0;
  }
  
  em {
    font-size: .95rem;
    text-align: center;
    display: inline-block;
    font-style: italic;
  }
  
  .center {
    text-align: center;
  }
  
  .settings-enter {
    transform: translateY(100px);
  }
  .settings-enter-active {
    transform: translateX(0);
    transition:  transform 300ms cubic-bezier(.10,.70,.10,1);
  }
  .settings-exit {
    opacity: 1;
  }
  .settings-exit-active {
    transform: translateY(100px);
    transition:  transform 300ms cubic-bezier(.10,.70,.10,1);
  }
  /*--------*/
  .navigation-enter {
    transform: translateX(-100px);
    opacity: 0;
  }
  .navigation-enter-active {
    transform: translateX(0);
    transition:  transform 300ms cubic-bezier(.10,.70,.10,1);
    opacity: 1;
  }
  .navigation-exit {
    opacity: 1;
  }
  .navigation-exit-active {
    transform: translateX(-100px);
    transition:  transform 300ms cubic-bezier(.10,.70,.10,1);
  }
  /*--------*/
  .entries-enter {
    transform: translateX(100%);
  }
  .entries-enter-active {
    position: relative;
    z-index: 10;
    transform: translateX(0);
    transition:  transform 300ms cubic-bezier(.10,.70,.10,1);
    box-shadow: -1px 0 1px rgba(0,0,0,0.08),
                -2px 0 2px rgba(0,0,0,0.12),
                -4px 0 4px rgba(0,0,0,0.16),
                -8px 0 8px rgba(0,0,0,0.20);
  }
  .entries-exit {
    //opacity: 1;
  }
  .entries-exit-active {
    position: relative;
    z-index: 10;
    transform: translateX(100%);
    transition:  transform 300ms cubic-bezier(.10,.70,.10,1);
    box-shadow: -1px 0 1px rgba(0,0,0,0.08),
                -2px 0 2px rgba(0,0,0,0.12),
                -4px 0 4px rgba(0,0,0,0.16),
                -8px 0 8px rgba(0,0,0,0.20);
  }
  
  @keyframes press {
    0% {
      transform: scale(0.90);
      opacity: 1;
    }
    
    50% {
      opacity: .5;
    }
    
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .fav, .remove-fav {
    animation: press 200ms ease-in-out;
  }
	
	.fav {
    position: relative;
    &:before {
      content:'';
      position: absolute;
      top: 0.5rem;
      bottom: 4.5rem;
      left: -1.5rem;
      width: 3px;
      border-top-right-radius: 1.5px;
      border-bottom-right-radius: 1.5px;
      background-color: #f14a3de6;
    }
  }

`
