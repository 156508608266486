import React from 'react'
import PropTypes from 'prop-types'
import Navigation from './Navigation.component'
import * as SC from '../ui-components/PageList.styles'
import { Container } from './PageNavigation.styles'

export default class PageNavigation extends React.PureComponent {

  render() {
    return(
      <Container>
        <Navigation/>
        <SC.PageList>
          <dt>{this.props.title}  {this.props.buttonAction}</dt>
					{ this.props.sectionDescription && <dd className="sectionDescription">{this.props.sectionDescription}</dd> }
          { this.props.navList }
        </SC.PageList>
      </Container>
    )
  }
}

PageNavigation.propTypes = {
  navList: PropTypes.any,
  title: PropTypes.string,
  buttonAction: PropTypes.any,
	sectionDescription: PropTypes.string
}