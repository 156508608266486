import styled from 'styled-components'

export const Navigation = styled.nav`
  flex: 0 0 60px;
  height: -webkit-fill-available;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(#F14A3D, #FE1651);
  //background: linear-gradient(#FF6CAB, #7366FF);
  //background: linear-gradient(#FFA62E, #EA4D2C);
  //background: linear-gradient(#FFCDA5, #EE4D5F);
  //background: linear-gradient(#F00B51, #750061);

  ul > li >  a {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 22px;
      height: 22px;
      color: #ffffff;
      opacity: .5;
      transition: opacity 100ms ease-in;
      will-change: opacity;
    }
    
    &.active {
      > svg {
        opacity: 1;
      }
    }
  }
`
