import styled from 'styled-components'

export const AppSettings = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${({theme}) => theme && theme.colorFont};
  display: flex;
  flex-direction: column;
  
  .container {
    background-color: ${({ theme }) =>theme && theme.colorSettings};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 -1px 1px rgba(0,0,0,0.08),
                0 -2px 2px rgba(0,0,0,0.12),
                0 -4px 4px rgba(0,0,0,0.16),
                0 -8px 8px rgba(0,0,0,0.20);
  }
  
  .section {
    flex: 1;
    display: flex;
    justify-content: space-between;
    border-top: solid 1px ${({ theme }) =>theme && theme.colorBorder};
  
    > * {
      flex: 1;
    }
  }
  
  button {
    height: 50px;
    
    &:last-of-type {
      border-left: solid 1px ${({ theme }) =>theme && theme.colorBorder};
    }
  }
`
