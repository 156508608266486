import React from 'react'
import { SvgIcons } from '../../assets/Svg'
import { Icons, Global, Type } from '../../Constants'
import * as SC from './HiddenWord.styles'
import PropTypes from "prop-types"
import { saveToStorage } from "../../helpers/LocalStorage"
import { Loader } from '../../assets/Loader'

export default class HiddenWord extends React.PureComponent {
  
  constructor(props) {
    super(props)
    this.state = {
      posts: []
    }
  }
  
  componentDidMount() {
    let postUrl = `${Global.URL}posts?per_page=100&categories=${this.props.categoryId}&order=asc`
    fetch(postUrl)
      .then(response => response.json())
      .then(response => {
        this.setState({
          posts: response
        })
      })
  }
 
  renderEntry() {
    let posts = this.state.posts.map((post, index) => {
      
      let className = ''
      
      let favorites = JSON.parse(localStorage.getItem(Type.FAVORITE) || "[]")
      favorites.map( fav => {
        if (fav.postId === post.id) {
          className = 'favorite-label'
        }
      })
			
      return (
        <article className={className} key={index} data-category-id={ this.props.categoryId } onClick={ (event) => saveToStorage(post.id, this.props.categoryName, post.content.rendered, post.acf.authors, event)}>
          <div dangerouslySetInnerHTML={{__html:post.content.rendered}}/>
          <p className="author">{post.title.rendered}</p>
          <div className="divider">
            { SvgIcons(Icons.TEXT_DIVIDER) }
          </div>
        </article>
      )
    })

    return (
      <SC.Entry>
        {posts}
      </SC.Entry>
    )
  }
  
  render() {
    
      return (
        this.state.posts.length ? this.renderEntry() : <Loader/>
      )
  }
}

HiddenWord.propTypes = {
  categoryId: PropTypes.any,
  categoryName: PropTypes.string
}
