import React from 'react'
import { SvgIcons } from '../../assets/Svg'
import {Icons, Type} from '../../Constants'
import * as SC from '../Prayers/Prayer.styles'
import PropTypes from "prop-types"

export default class Favorite extends React.PureComponent {
  
  constructor(props) {
    super(props)
    this.state = {
      posts: [],
    }
  }
  
  componentDidMount() {
    const { id } = this.props
    let favorites = JSON.parse(localStorage.getItem(Type.FAVORITE) || "[]")
    favorites.map(favorite => {
      if (id === favorite.postId) {
        return this.setState({
          posts: favorite
        })
      } else {
        return null
      }
    })
  }
  
  renderEntry() {
    const { author, content } = this.state.posts
    return (
      <SC.Entry>
        <article>
          <div dangerouslySetInnerHTML={{__html:content}}/>
					{author && <p className="author">{author}</p>}
          <div className="divider">
            { SvgIcons(Icons.TEXT_DIVIDER) }
          </div>
        </article>
      </SC.Entry>
    )
  }
  
  render() {
      return (
        this.renderEntry()
      )
  }
}

Favorite.propTypes = {
  id: PropTypes.any,
}
