import React, { Fragment } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Type, Icons } from '../../Constants'
import PageNavigation from '../PageNavigation.component'
import FavoritesContainer from './Favorites.container'
import { SvgIcons } from '../../assets/Svg'

export default class Favorites extends React.PureComponent {
  constructor(props) {
    super(props)
    
    this.state = {
      posts: [],
      postId: null,
      showNavigation: true,
      categoryName: '',
      cleared: false
    }
  }
  
  componentDidMount() {
    this.setState({
      posts: JSON.parse(localStorage.getItem(Type.FAVORITE))
    })
  }
  
  togglePage() {
    this.setState(prevState => ({
      showNavigation: !prevState.showNavigation,
    }))
  }
  
  goToPost(id, categoryName) {
    this.setState(prevState => ({
      showNavigation: !prevState.showNavigation,
      postId: id,
      categoryName: categoryName
    }))
  }
  
  clearStorage = () => {
    localStorage.removeItem("FAVORITE")
    this.setState({
      cleared: true
    })
  }
  
  clearStorageFavorites() {
    return <button style={{marginRight: '2.5rem', float: 'right'}} onClick={this.clearStorage}>{SvgIcons(Icons.DELETE)}</button>
  }
  
  renderNavigation() {
    if (!this.state.posts || this.state.cleared) {
      return <dd style={{ paddingBottom: "1rem"}}>Za dodajanje med zaznamke se dvakrat dotakni molitve.</dd>
    }
    const posts = this.state.posts.map((post, index) => {
      return (
        <dd key={index}>
          <button onClick={ () => this.goToPost(post.postId, post.categoryName)}>
            <span className="excerpt">{post.excerpt}</span>
            <span className="author">{post.author}, {post.categoryName}</span>
          </button>
        </dd>
      )
    })

    return posts
  }
  
  render() {
    return (
      <Fragment>
    
        <CSSTransition in={this.state.showNavigation} classNames="navigation" timeout={300} unmountOnExit>
          <PageNavigation navList={this.renderNavigation()} title="Zaznamki" buttonAction={ this.clearStorageFavorites() } />
        </CSSTransition>
    
        <CSSTransition in={!this.state.showNavigation} classNames="entries" timeout={300} unmountOnExit>
          <FavoritesContainer id={ this.state.postId } categoryName={ this.state.categoryName } toggleHandler={ this.togglePage.bind(this) }/>
        </CSSTransition>
  
      </Fragment>
    )
  }
}
