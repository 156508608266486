import React from 'react'
import Navigation from '../Navigation.component'
export default class Search extends React.PureComponent {
  searchResults() {
    //TODO implement search http://localhost:8888/wp-json/wp/v2/posts?search={searchString}&_embed
  }
  render() {
    return(
      <div>
        <Navigation/>
        <div>
        Search</div></div>
    )
  }
}