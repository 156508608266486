import React from 'react'
import PropTypes from 'prop-types'
import HiddenWord from './HiddenWord'
import Footer from '../../components/Footer'
import * as SC from '../../ui-components/Container.styles'

export default class HiddenWordsContainer extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			prevScrollPosition: window.pageYOffset,
			visible: true,
		}
		
		this.scrollContainer = React.createRef()
		this.container = React.createRef()
	}
	
	// componentDidMount() {
	//   window.addEventListener("scroll", this.handleScroll)
	// }
	
	// componentWillUnmount() {
	//   window.removeEventListener("scroll", this.handleScroll)
	// }
	
	handleScroll = () => {
		const { prevScrollPosition } = this.state
		
		const currentScrollPosition = window.pageYOffset
		const visible = prevScrollPosition > currentScrollPosition || this.container.current.scrollHeight === currentScrollPosition + window.innerHeight
		
		this.setState({
			prevScrollPosition: currentScrollPosition,
			visible
		})
	}
	
	render() {
		return (
			<SC.Container ref={ this.scrollContainer }>
				<section ref={ this.container }>
					<h2>{ this.props.categoryName }</h2>
					<HiddenWord categoryId={ this.props.id } categoryName={ this.props.categoryName } />
				</section>
				<Footer hide={ false } toggleHandler={ this.props.toggleHandler } />
			</SC.Container>
		)
	}
}

HiddenWordsContainer.propTypes = {
	children: PropTypes.any,
	toggleHandler: PropTypes.func,
	categoryName: PropTypes.string,
	id: PropTypes.number
}
