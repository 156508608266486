import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import Favorites from './Favorites'
import Search from './Search'
import Prayers from './Prayers'
import HiddenWords from './HiddenWords'
import HiddenWordsContainer from './HiddenWords/HiddenWords.container'
import PrayersContainer from './Prayers/Prayers.container'
import * as SC from './Page.style'

export default class Page extends React.PureComponent {
  render() {
    return(
      <Router>
        <SC.Page>
          <Switch>
            <Route exact path="/" component={ Prayers }/>
						<Route path="/hidden-words" component={ HiddenWords }/>
            <Route path="/favorites" component={ Favorites }/>
            <Route path="/search" component={ Search }/>
            <Route path="/prayers/:categoryId" component={ PrayersContainer }/>
						<Route path="/hidden-words/:categoryId" component={ HiddenWordsContainer }/>
          </Switch>
        </SC.Page>
      </Router>
    )
  }
}