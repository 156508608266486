import { Type } from '../Constants'
import { isDoubleTap } from './DoubleTap'

export function saveToStorage(postId, categoryName, content, author, e) {
  
  if (isDoubleTap(e.currentTarget)) {
    const item =  {
      postId: postId,
      categoryName: categoryName,
      content: content,
      excerpt: content.substring(0, 100).replace(/(<([^>]+)>)/ig,""),
      author: author
    }
  
    let favorites = JSON.parse(localStorage.getItem(Type.FAVORITE) || "[]")
  
    const found = favorites.some(el => el.postId === item.postId)
    
    if (found) {
      const index = favorites.map(el => el.postId).indexOf(item.postId)
      favorites.splice(index, 1);
      localStorage.setItem(Type.FAVORITE, JSON.stringify(favorites));
      e.currentTarget.classList.remove('fav')
      e.currentTarget.classList.add('remove-fav')
    }
    if (!found) {
      favorites.push(item)
      localStorage.setItem(Type.FAVORITE, JSON.stringify(favorites))
      // TODO UI notification for flagging 'added to favs'
      e.currentTarget.classList.add('fav')
      e.currentTarget.classList.remove('remove-fav')
    }
  }
}

export function saveThemeToStorage(bodyColor, fontColor, secondaryColor, fontFamily, fontSize) {
  const themeItems = {
    bodyColor: bodyColor,
    fontColor: fontColor,
    secondaryColor: secondaryColor,
    fontFamily: fontFamily,
    fontSize: fontSize
  }
  
  localStorage.setItem(Type.SAVE_THEME, JSON.stringify(themeItems))
}
