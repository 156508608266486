import React from 'react'
import PropTypes from 'prop-types'
import Footer from '../../components/Footer'
import * as SC from '../../ui-components/Container.styles'
import Favorite from './Favorite'

export default class FavoritesContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      prevScrollPosition: window.pageYOffset,
      visible: true,
    }
    
    this.container = React.createRef()
  }
  //
  // componentDidMount() {
  //   window.addEventListener("scroll", this.handleScroll)
  //
  // }
  //
  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.handleScroll)
  // }
  
  handleScroll = () => {
    const { prevScrollPosition } = this.state
    
    const currentScrollPosition = window.pageYOffset
    const visible = prevScrollPosition > currentScrollPosition || this.container.current.scrollHeight === currentScrollPosition + window.innerHeight
    
    this.setState({
      prevScrollPosition: currentScrollPosition,
      visible
    })
  }
  
  render() {
    return (
      <SC.Container ref={ this.container }>
        <section>
          <h2>{ this.props.categoryName }</h2>
          <Favorite id={ this.props.id } />
        </section>
        <Footer hide={ false } toggleHandler={ this.props.toggleHandler } />
      </SC.Container>
  )
  }
}

FavoritesContainer.propTypes = {
  children: PropTypes.any,
  toggleHandler: PropTypes.func,
  id: PropTypes.number,
  categoryName: PropTypes.string
}
