import React from 'react'
import PropTypes from 'prop-types'
import { FontSize } from '../Constants'
import { themes } from '../theme/globalStyle'

export const AppContext = React.createContext()

export class AppProvider extends React.Component {
  state = {
    theme: themes.light,
    toggleTheme: true,
    fontSize: FontSize.FONT_SIZE
  }
  
  handleThemeChange = () => {
    this.setState({ 
      theme: this.state.toggleTheme ? themes.dark : themes.light, 
      toggleTheme: !this.state.toggleTheme 
    })
  }
  
  handleFontSizeUp = () => {
    this.setState({ 
      fontSize: this.state.fontSize + 1
    })
  }

  handleFontSizeDown = () => {
    this.setState({ 
      fontSize: this.state.fontSize - 1
    })
  }
  
  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          handleThemeChange: this.handleThemeChange,
          handleFontSizeUp: this.handleFontSizeUp,
          handleFontSizeDown: this.handleFontSizeDown,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

AppProvider.propTypes = {
  children: PropTypes.any
}
