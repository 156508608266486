import React, { Fragment } from 'react'
import { CSSTransition } from 'react-transition-group'
import PageNavigation from '../PageNavigation.component'
import HiddenWordsContainer from '../HiddenWords/HiddenWords.container'
import {Global} from "../../Constants";

export default class HiddenWords extends React.PureComponent {
	constructor(props) {
		super(props)
		
		this.state = {
			categories: [],
			categoryId: null,
			toggleHeader: true,
			categoryName: '',
		}
	}
	
	componentDidMount() {
		let categoriesUrl = `${Global.URL}categories?per_page=100&&order=desc`
		fetch(categoriesUrl)
			.then(response => response.json())
			.then(response => {
				this.setState({
					categories: response
				})
			})
	}
	
	goToCategoryPage(id, categoryName) {
		this.setState(prevState => ({
			toggleHeader: !prevState.toggleHeader,
			categoryId: id,
			categoryName: categoryName
		}))
	}
	
	togglePage() {
		this.setState(prevState => ({
			toggleHeader: !prevState.toggleHeader
		}))
	}
	
	renderNavigation() {
		const categories = this.state.categories.map((category, index) => {
			if ( category.id === 36 || category.id === 37 ) {
				return (
					<dd key={index}>
						<button onClick={() => this.goToCategoryPage(category.id, category.name)} data-url={category.id}>
							{category.name}
						</button>
					</dd>
				)
			}
		})
		
		return categories
	}
	
	render() {
		const sectionDescription = "Prebiraj skrite besede, Razmišljaj o njihovem pomenu in naj se odražajo v tvojem življenju. Vse, kar bi lahko povedal, je samo kapljica iz tega neskončnega morja."
		
		return(
			<Fragment>
				
				<CSSTransition in={this.state.toggleHeader} classNames="navigation" timeout={300} unmountOnExit>
					<PageNavigation navList={this.renderNavigation()} title="Skrite besede" sectionDescription={sectionDescription} />
				</CSSTransition>
				
				<CSSTransition in={!this.state.toggleHeader} classNames="entries" timeout={300} unmountOnExit>
					<HiddenWordsContainer categoryName={ this.state.categoryName } id={ this.state.categoryId } toggleHandler={ this.togglePage.bind(this) }/>
				</CSSTransition>
			
			</Fragment>
		)
	}
}