import React from 'react'
import { connect } from "react-redux"
import * as SC from './AppSettings.styles'
import ThemeSelect from '../ThemeSelect';
import {IncreaseFont, DecreaseFont} from '../FontResize'

class AppSettings extends React.PureComponent {
  render() {
    const { clickHandler } = this.props
    return (
        <SC.AppSettings>
          <div style={{flex:'1'}} onClick={clickHandler}/>
          <div className="container">
            <div className="section">
              <DecreaseFont/>
              <IncreaseFont/>
            </div>
            <div className="section">
              <ThemeSelect/>
            </div>
          </div>
        </SC.AppSettings>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps)(AppSettings)