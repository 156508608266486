import React from 'react'
import { NavLink } from 'react-router-dom'
import { SvgIcons } from "../assets/Svg"
import { Icons } from "../Constants"
import * as SC from './Navigation.style'

export default class Navigation extends React.PureComponent {
  render() {
    return (
      <SC.Navigation>
        <ul>
					<li><NavLink exact to={'/'}>{SvgIcons(Icons.CONTENT_LIST)}</NavLink></li>
					<li><NavLink exact to={'/hidden-words'}>{SvgIcons(Icons.HIDDEN_WORDS)}</NavLink></li>
          <li><NavLink exact to={'/favorites'}>{SvgIcons(Icons.FAVOURITE)}</NavLink></li>
          <li><NavLink exact to={'/search'}>{SvgIcons(Icons.SEARCH)}</NavLink></li>
        </ul>
      </SC.Navigation>
    )
  }
}
