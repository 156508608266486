import React from 'react'
import PropTypes from 'prop-types'
import { AppContext } from './context/AppContext'
import { SvgIcons } from "./assets/Svg"
import { Icons } from "./Constants"

const IncreaseFont = () => {
  return (
    <AppContext.Consumer>
      {({ handleFontSizeUp }) => (
        <button onClick={e => handleFontSizeUp(e)}>{SvgIcons(Icons.FONT_UP)}</button>
      )}
    </AppContext.Consumer>
  )
}

const DecreaseFont = () => {
  return (
    <AppContext.Consumer>
      {({ handleFontSizeDown }) => (
        <button onClick={e => handleFontSizeDown(e)}>{SvgIcons(Icons.FONT_DOWN)}</button>
      )}
    </AppContext.Consumer>
  )
}

IncreaseFont.propTypes = {
  handleFontSizeUp: PropTypes.func
}

DecreaseFont.propTypes = {
  handleFontSizeDown: PropTypes.func
}

export { IncreaseFont, DecreaseFont }
