import React from 'react'
import PropTypes from 'prop-types'
import { AppContext } from './context/AppContext'
import { SvgIcons } from "./assets/Svg"
import { Icons } from "./Constants"

const ThemeSelect = () => {
  return (
    <AppContext.Consumer>
      {({ handleThemeChange, toggleTheme }) => (
        <button onClick={e => handleThemeChange(e)}>{toggleTheme ? SvgIcons(Icons.NIGHT_TIME) : SvgIcons(Icons.DAY_TIME)}</button>
      )}
    </AppContext.Consumer>
  )
}

ThemeSelect.propTypes = {
  handleThemeChange: PropTypes.func,
}

export default ThemeSelect
