import styled, { css, keyframes } from 'styled-components'

const animateUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`

const animateDown = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
`

export const Footer = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: var(--footer-height);
    border-top: solid 1px ${({ theme }) =>theme && theme.colorBorder};
    background-color: ${({ theme }) =>theme && theme.colorBody};
    display: flex;
    align-items: center;
    
    ${({ hideFooter}) => hideFooter ? css`
      animation: ${animateDown} 250ms cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
      animation-delay: 500ms;
      ` : css`
      animation: ${animateUp} 300ms cubic-bezier(0.4, 0.0, 0.2, 1) forwards;`}
    
    > button {
      flex: 1;
      height: 100%;
      
      &.back {
        border-right: solid 1px ${({ theme }) =>theme && theme.colorBorder};
      }
    }
    
`
